<div class="container-fluid">
  <div class="row">
    <div class="ml-auto mr-auto col-md-6 text-center">
      <h1 class="display-3 text-warning">Error</h1>
      <p class="lead">
        Oops! We are experiencing technical difficulties. Please try back later.
      </p>
      <p class="lead">
        <a href="/" class="btn btn-outline-warning btn-lg rounded-circle"><i class="fa fa-refresh fa-5x"></i></a><br />
        <a href="/" class="text-warning">start over</a>
      </p>

    </div>
  </div>
</div>
