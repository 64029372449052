<nav class="navbar fixed-top navbar-inverse bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" (click)="goToDashboard()">
      <i class="fa fa-circle-o text-info"></i>
      <i class="fa fa-circle-o text-info"></i>
      <i class="fa fa-check-circle text-orange"></i>
      <i class="fa fa-circle-o text-info"></i>
    </a>
    <div class="btn-group" dropdown *ngIf="loggedInUser.permissions['can-access-admin']">
      <button dropdownToggle type="button" class="btn btn-link dropdown-toggle text-info">
        <i class="fa fa-gear"></i> <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin']"><i class="fa fa-dashboard"></i> Admin Dashboard</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/sessions']"><i class="fa fa-clock-o"></i> Assigned Tests</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/reports']"><i class="fa fa-line-chart"></i> Smarty Reports</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/wizard']"><i class="fa fa-magic"></i> Smarty Test Wizard</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/tests']"><i class="fa fa-list-ol"></i> Smarty Tests</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/admin/users']"><i class="fa fa-user"></i> Smarty Users</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/proctor']"><i class="fa fa-users"></i> Proctor</a></li>
        <li role="menuitem" *ngIf="loggedInUser.role === 'Smarty Admin'"><a class="dropdown-item" [routerLink]="['/admin/track']"><i class="fa fa-search"></i> Organizations</a></li>
        <li role="menuitem" *ngIf="loggedInUser.role === 'Smarty Admin'"><a class="dropdown-item" [routerLink]="['/admin/logs']"><i class="fa fa-history"></i> Proctor Logs</a></li>

      </ul>
    </div>
    <div class="btn-group" dropdown *ngIf="loggedInUser.role === 'Student'">
      <button dropdownToggle type="button" class="btn btn-link dropdown-toggle text-info">
        <i class="fa fa-gear"></i> <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/dashboard']"><i class="fa fa-dashboard"></i> Dashboard</a></li>
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/student/tests']"><i class="fa fa-clock-o"></i> Assigned Tests</a></li>
      </ul>
    </div>
    <div class="btn-group" dropdown>
      <button dropdownToggle type="button" class="btn btn-link dropdown-toggle text-info">
        <span class="d-none d-sm-inline">{{loggedInUser.username}}</span><span class="d-inline d-sm-none"><i class="fa fa-user"></i></span> <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/logout']">Sign Out</a></li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
