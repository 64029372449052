<div class="d-flex align-items-center justify-content-center full-height">
  <div class="register-wrapper pl-4 pr-4">
    <div class="card">
      <div class="card-body">
        <h1 class="text-center text-muted">Welcome to</h1>
        <h1 class="text-center"><span class="text-orange">smarty</span><span class="text-info">bubble</span> <i class="fa fa-check-circle text-orange"></i></h1>
        <p class="text-center text-muted">Register</p>
        <div class="row">
          <div class="col">
            <form [formGroup]="registerForm" novalidate autocomplete="off" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">First name</span>
                    </span>
                    <input type="text" class="form-control" formControlName="firstName"
                       [ngClass]="{
                          'border-danger': (this.firstNameForm.touched || this.firstNameForm.dirty) && !this.firstNameForm.valid
                       }">
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Last Name</span>
                    </span>
                    <input type="text" class="form-control" formControlName="lastName"
                       [ngClass]="{
                          'border-danger': (this.lastNameForm.touched || this.lastNameForm.dirty) && !this.lastNameForm.valid
                       }">
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Email</span>
                    </span>
                    <input type="text" class="form-control" formControlName="email"
                           [ngClass]="{
                          'border-danger': (this.emailForm.touched || this.emailForm.dirty) && !this.emailForm.valid
                       }">
                  </div>
                </div>


                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Parent first name</span>
                    </span>
                    <input type="text" class="form-control" formControlName="parentFirstName"
                           [ngClass]="{
                          'border-danger': (this.parentFirstNameForm.touched || this.parentFirstNameForm.dirty) && !this.parentFirstNameForm.valid
                       }">
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Parent last Name</span>
                    </span>
                    <input type="text" class="form-control" formControlName="parentLastName"
                           [ngClass]="{
                          'border-danger': (this.parentLastNameForm.touched || this.parentLastNameForm.dirty) && !this.parentLastNameForm.valid
                       }">
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Parent email</span>
                    </span>
                    <input type="text" class="form-control" formControlName="parentEmail"
                           [ngClass]="{
                          'border-danger': (this.parentEmailForm.touched || this.parentEmailForm.dirty) && !this.parentEmailForm.valid
                       }">
                  </div>
                </div>
                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Parent phone</span>
                    </span>
                    <input type="text" class="form-control" formControlName="parentPhone"
                           [ngClass]="{
                          'border-danger': (this.parentPhoneForm.touched || this.parentPhoneForm.dirty) && !this.parentPhoneForm.valid
                       }">
                  </div>
                </div>


                <div class="col-sm-12 mb-3">
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text">Time</span>
                    </span>
                    <select class="form-control" formControlName="extraTime">
                      <option value="">Regular</option>
                      <option value="extended">1.5x</option>
                    </select>
                  </div>
                </div>
              </div>
<!--              <div class="row" formGroupName="password">-->
<!--                <div class="col-sm-12 mb-3">-->
<!--                  <div class="input-group">-->
<!--                    <span class="input-group-prepend">-->
<!--                      <span class="input-group-text">Password</span>-->
<!--                    </span>-->
<!--                    <input type="password" class="form-control" formControlName="pass"-->
<!--                           [ngClass]="{-->
<!--                          'border-danger': (this.passForm.touched || this.passForm.dirty) && !this.passForm.valid-->
<!--                       }">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-sm-12 mb-3">-->
<!--                  <div class="input-group">-->
<!--                    <span class="input-group-prepend">-->
<!--                      <span class="input-group-text">Confirm Password</span>-->
<!--                    </span>-->
<!--                    <input type="password" class="form-control" formControlName="confirmPass"-->
<!--                           [ngClass]="{-->
<!--                          'border-danger': (this.confirmPassForm.touched || this.confirmPassForm.dirty) && !this.confirmPassForm.valid-->
<!--                       }">-->
<!--                    <span *ngIf="this.confirmPassForm.getError('matchPassword')" class="text-danger d-block w-100">-->
<!--                      Passwords doesn't match</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

              <div class="text-center mt-3">
                <button type="submit" [disabled]="!registerForm.valid" class="btn btn-success" [ladda]="isLoading" data-style="slide-right">
                  Let's Go <i class="fa fa-arrow-circle-right"></i>
                </button>
              </div>
              <div class=" text-center text-muted">
                <a href="/login" target="_blank" class="btn btn-link text-muted" >
                  <small>Log in</small>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
